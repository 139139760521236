@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  margin: 0;

  background: url("./assets/General/DafiBackground.png") no-repeat;
  background-size: cover;
  background-position: top;
  background-attachment: fixed;
}
.walletconnect-modal__base {
  top: 19px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  overflow: scroll;
  overflow-x: hidden;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  /* overflow-y: hidden; */
}

/* Tracks */
::-webkit-scrollbar-track {
  background: "rgba(255, 255, 255, 0.15)";
  width: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #00e8fe;
  border-radius: 10px;
}

.progress-bar-vertical {
  width: 80px;
  height: 230px;
  display: flex;
  align-items: flex-end;
  margin-bottom: 16px;

  background: rgba(15, 8, 36, 0.55);
  border: 1px solid rgba(230, 231, 233, 0.1);
  border-radius: 10px;
  box-shadow: inset 0px 23.0177px 33.051px -33px rgba(255, 255, 255, 0.5),
    inset 0px 4.13137px 6.49216px -2.36078px rgba(255, 255, 255, 0.25),
    inset 0px -48.3961px 40.1333px -37.7725px rgba(255, 255, 255, 0.12),
    inset 0px 57.8392px 59.0196px -28.3294px rgba(255, 255, 255, 0.12),
    inset 0px 2.36078px 10.6235px rgba(8, 5, 18, 0.2),
    inset 0px 0.590196px 23.6078px rgba(8, 5, 18, 0.2);

  -webkit-box-shadow: inset 0px 23.0177px 33.051px -33px rgba(255, 255, 255, 0.5),
    inset 0px 4.13137px 6.49216px -2.36078px rgba(255, 255, 255, 0.25),
    inset 0px -48.3961px 40.1333px -37.7725px rgba(255, 255, 255, 0.12),
    inset 0px 57.8392px 59.0196px -28.3294px rgba(255, 255, 255, 0.12),
    inset 0px 2.36078px 10.6235px rgba(8, 5, 18, 0.2),
    inset 0px 0.590196px 23.6078px rgba(8, 5, 18, 0.2);

  overflow: hidden;
}

.progress-bar-vertical .progress-bar {
  width: 100%;

  -webkit-transition: height 0.6s ease;
  -o-transition: height 0.6s ease;
  transition: height 0.6s ease;
}

.progress-label {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 10px;
  text-align: center;
}

.MuiBackdrop-root {
  background: rgba(15, 8, 36, 0.55) !important;
  backdrop-filter: blur(25px);
  -webkit-backdrop-filter: blur(25px);
}

.MuiListItem-button:hover {
  background-color: transparent !important;
}

.MuiListItemSecondaryAction-root {
  right: 0 !important;
}

/* .makeStyles-toolbar-6,
  .makeStyles-toolbar-478 {
    height: 100% !important;
  } */
.MuiBox-root-252 > .MuiButton-root {
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.slick-dots.li.button::before {
  color: "#FFFFFF";
}

@keyframes spin {
  100% {
    -webkit-transform: rotateY(-360deg);
    transform: rotateY(-360deg);
  }
}

.dafiLogoAnimated {
  animation: spin 2s ease-in-out infinite 2s;
}

.full-page-loader {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background: url("./assets/General/DafiBackground.png") no-repeat;
  background-size: cover;
  background-position: top;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 1000000;
}

.full-page-loader-image {
  height: 250px;
}

.addDafiBox-tooltip {
  height: 50px;
  width: 50px;
  background: linear-gradient(104.25deg, #829eff 0%, #c549ff 100%);

  border: 1px solid rgba(255, 255, 255, 0.28) !important;
  border-radius: 10px !important;
  margin-right: 16px !important;

  position: relative !important;
}

.addDafiBox-tooltipText {
  visibility: hidden;
  min-width: 260px;
  height: 60px;

  color: #fff;
  text-align: center;

  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -5px;
  right: 105%;

  background: linear-gradient(139.63deg, #605384 -23.88%, #0f0824 100%);
  border: 1px solid rgba(255, 255, 255, 0.28);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
}

.addDafiBox-tooltip:hover .addDafiBox-tooltipText {
  visibility: visible;
}

.addDafiBox-tooltipText::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 100%; /* To the right of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent #41375f;
}

.networkBtn-tooltip {
  height: 50px;
  background: rgba(15, 8, 36, 0.55);
  border: 1px solid rgba(255, 255, 255, 0.28) !important;
  border-radius: 10px !important;
  margin-right: 15px !important;
  width: fit-content;
  position: relative !important;
}

.networkBtn-tooltip-content {
  visibility: hidden;
  width: 500px;
  height: 60px;

  color: #fff;
  text-align: center;

  padding: 10px 16px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;

  background: linear-gradient(139.63deg, #605384 -23.88%, #0f0824 100%);
  border: 1px solid rgba(255, 255, 255, 0.28);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -215px;
}

.networkBtn-tooltip:hover .networkBtn-tooltip-content {
  visibility: visible;
}

.slick-list {
  margin: 0 -5px;
}
.slick-slide > div {
  padding: 0 5px;
}

.networkBtn-tooltip-content::after {
  content: " ";
  position: absolute;
  bottom: 100%; /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #41375f transparent;
}

@media only screen and (max-width: 600px) {
  .networkBtn-tooltip {
    display: none !important;
  }

  .addDafiBox-tooltip {
    display: none !important;
  }
}

@media only screen and (max-width: 500px) {
  .networkBtn-tooltip {
    width: 90px;
  }
}

@media only screen and (max-width: 400px) {
  .networkBtn-tooltip {
    width: 80px;
    margin-right: 8px;
  }
}

.launch-screen {
  background: url("./assets/General/LaunchScreen.png") no-repeat;
  background-size: cover;
  background-position: center;

  width: 100vw;
  height: 100vh;
}

@media only screen and (max-width: 400px) {
  .launch-screen {
  }
}

iframe {
  display: none;
}
#apexchartsnbmdte7ii {
  height: 100% !important;
}

.MuiTableContainer-root tr::after {
  content: " ";
  display: block;
  visibility: hidden;
  clear: both;
}

.App {
  text-align: center;
  padding: 20px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.apexPieChart .apexcharts-svg {
  height: 100% !important;
}
.apexPieChart,
.apexPieChart > div,
.apexcharts-canvas {
  height: 100% !important;
}
.MuiAlert-root {
  background: linear-gradient(
    182.75deg,
    rgba(96, 83, 132, 0.7) -94.02%,
    rgba(15, 8, 36, 0.7) 153.25%
  ) !important ;

  background-color: #0f0824 !important;
  color: #fff !important;
}

.MuiCardContent-root:last-child {
  overflow: hidden;
}

.MuiAlert-icon {
  /* color: #0aebf2 !important; */
}
/* snackBar: {
    "& .MuiAlert-root": {
      backgroundColor: "red !important",
    },
    "& .MuiAlert-standardSuccess ": {
      backgroundColor: "red !important",
    },
  }, */
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.approve-container {
}

.slick-prev {
  left: -22px;
}
.css-1dk1h2u-MuiSkeleton-root {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.MuiInputBase-input.Mui-disabled {
  color: #fff;
}
